const content = {
	content: 's unde omnis iste natu',
	createdBy: {
		name: 'VEX',
	},
	id: '1223444454',
	createdAt: '2024-09-30T13:20:43.612+00:00',
};

const content2 = {
	content: 'random text',
	createdBy: {
		name: 'Test user 1',
	},
	id: '1223666454',
	createdAt: '2024-10-12T13:20:43.612+00:00',
};

const content3 = {
	content: 'ror sit volup',
	createdBy: {
		name: 'Trace Approver',
	},
	id: '1224443454',
	createdAt: '2024-10-30T13:20:43.612+00:00',
};

const content4 = {
	content: 'ror sit volup',
	createdBy: {
		name: 'VKO',
	},
	id: '1222223454',
	createdAt: '2024-10-30T13:20:43.612+00:00',
};

const content5 = {
	content: 'ror sit volup',
	createdBy: {
		name: 'Admin',
	},
	id: '12234444454',
	createdAt: '2024-10-30T13:20:43.612+00:00',
};

const content6 = {
	content: 'ror sit volup',
	createdBy: {
		name: 'Admin',
	},
	id: '12333323454',
	createdAt: '2024-10-30T13:20:43.612+00:00',
};

const content7 = {
	content: 'ror sit volup',
	createdBy: {
		name: 'VKO 2',
	},
	id: '1223453334',
	createdAt: '2024-10-30T13:20:43.612+00:00',
};

const content8 = {
	content: 'ror sit volup',
	createdBy: {
		name: 'VKO 3',
	},
	id: '1221113454',
	createdAt: '2024-10-30T13:20:43.612+00:00',
};
export const questions1 = [
	{
		content:
			'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae v',
		createdBy: {
			name: 'Zaneta Saj',
		},
		createdAt: '2024-09-30T13:20:43.612+00:00',
		id: '1223454',
		answers: [
			content,
			content2,
			content3,
			content4,
			content5,
			content6,
			content7,
			content8,
		],
	},
	{
		content: 'question 2',
		createdBy: {
			name: 'Zaneta Saj',
		},
		createdAt: '2024-09-30T13:20:43.612+00:00',
		id: '12234354',
		answers: [],
	},
	{
		content: 'question 3',
		createdBy: {
			name: 'Zaneta Saj',
		},
		createdAt: '2024-09-30T13:20:43.612+00:00',
		id: '1223453334',
		answers: [content8, content2, content3, content4, content5],
	},
	{
		content: 'question 4',
		createdBy: {
			name: 'Zaneta Saj',
		},
		createdAt: '2024-09-30T13:20:43.612+00:00',
		id: '1221113454',
		answers: [content],
	},
];

export const questions2 = [
	{
		content: 'question 1',
		createdBy: {
			name: 'Zaneta Saj',
		},
		id: '122345444',
		createdAt: '2024-09-30T13:20:43.612+00:00',
		answers: [content2, content, content],
	},
];
