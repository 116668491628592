import {DefaultButton, Panel, PanelType, PrimaryButton} from '@fluentui/react';

import {useTranslation} from 'react-i18next';
import {useStore} from 'react-context-hook';
import React, {useState} from 'react';

import _ from 'lodash';
import {CreateQuestionDialog} from './CreateQuestionDialog';
import {FaqPanelContent} from './FaqPanelContent';

export const FaqPanel: any = () => {
	const [panel, setPanel] = useStore('panel', false);

	const {t} = useTranslation('common/faq');

	const [showQuestionDialog, setShowQuestionDialog] = useState(false);

	const onRenderFooterContent: any = () => (
		<form>
			<PrimaryButton
				style={{marginRight: '10px'}}
				onClick={() => setShowQuestionDialog(true)}
			>
				{t('PostQuestion')}
			</PrimaryButton>
			<DefaultButton
				text={t('Cancel')}
				onClick={() => {
					setPanel(false);
				}}
			/>
		</form>
	);

	return (
		<Panel
			type={PanelType.large}
			isLightDismiss
			isOpen={panel}
			onDismiss={() => {
				setPanel(false);
			}}
			closeButtonAriaLabel='Close'
			headerText={t('Header')}
			onRenderFooterContent={onRenderFooterContent}
			isFooterAtBottom={true}
		>
			<FaqPanelContent />
			<CreateQuestionDialog
				showDialog={showQuestionDialog}
				setShowDialog={setShowQuestionDialog}
			/>
		</Panel>
	);
};
