import {Requirement, VexCluster} from 'types';
import {TFunction} from 'react-i18next';
import {
	IHTMLXL,
	IArrayRoles,
	renderHeaderXL,
	renderHTMLExcel,
	cleanHTMLExcel,
	createZIPXL,
} from './ExcelExportCommon';
import Excel from 'exceljs';

interface IRequirementPage {
	(
		entityListItems: any[],
		arrRoles: IArrayRoles,
		myVexClusters: VexCluster[],
		t: TFunction,
	): Promise<void>;
}

export const handleClickRequirements: IRequirementPage = async (
	entityListItems,
	arrRoles,
	myVexClusters,
	t,
) => {
	let filtRequirement = entityListItems as Requirement[];

	if (arrRoles.isVex) {
		filtRequirement = filtRequirement.filter(x =>
			x.vexClusters.some(vc => myVexClusters.some(mvc => mvc.id === vc.id)),
		);
	}

	const arrImagesUri: string[] = [];
	const arrImagesName: string[] = [];
	const arrTables: string[] = [];

	const workbook = new Excel.Workbook();
	const sheet = workbook.addWorksheet(
		'TRACE_' +
			t('Regulations', {
				ns: 'features/regulations',
				keyPrefix: 'RegulationsList',
			}),
	);
	const arrColWidth: number[] = [30, 40, 80, 30, 20, 20, 20, 20, 20, 20];
	const strPageName = t('RequirementsHeader', {ns: 'features/requirements'});

	renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);

	let intRowCount = 4;
	let row = sheet.getRow(intRowCount);
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};

	sheet.addTable({
		name: 'tableRequirements',
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: [
			{
				name: t('RequirementId', {ns: 'features/requirements'}),
				filterButton: true,
			},
			{name: t('Name', {ns: 'features/requirements'}), filterButton: true},
			{
				name: t('Definition', {ns: 'features/requirements'}),
				filterButton: true,
			},
			{
				name: t('VexCluster', {ns: 'features/requirements'}),
				filterButton: true,
			},
			{name: t('Status', {ns: 'features/requirements'}), filterButton: true},
			{
				name: t('SystemLevels', {ns: 'features/requirements'}),
				filterButton: true,
			},
			{name: t('Markets', {ns: 'features/requirements'}), filterButton: true},
			{name: t('Keywords', {ns: 'features/requirements'}), filterButton: true},
			{name: t('Tags', {ns: 'features/requirements'}), filterButton: true},
			{name: t('Version', {ns: 'features/requirements'}), filterButton: true},
		],
		rows: [],
	});

	const table = sheet.getTable('tableRequirements');

	intRowCount++;
	const tempDivElement = document.createElement('div');
	let arrHTMLXL: IHTMLXL[];
	let arrHTMLXLClean: IHTMLXL[];

	if (filtRequirement.length === 0) {
		table.addRow(['', '', '', '', '', '', '', '', '', '']);
		intRowCount++;
	}

	for (let ii = 0; ii < filtRequirement.length; ii++) {
		arrHTMLXL = [];
		arrHTMLXLClean = [];
		tempDivElement.innerHTML = filtRequirement[ii].definition
			? '<div>' + filtRequirement[ii].definition + '</div>'
			: '<div></div>';
		renderHTMLExcel(tempDivElement.childNodes[0].childNodes, arrHTMLXL);
		cleanHTMLExcel(arrHTMLXL, arrHTMLXLClean);

		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};
		table.addRow([
			filtRequirement[ii].requirementId,
			filtRequirement[ii].name,
			{richText: arrHTMLXLClean, wrapText: true, vertical: 'top'},
			filtRequirement[ii].vexClusters.map(cluster => cluster.name).join(';\n'),
			t(filtRequirement[ii].status ?? '', {
				ns: 'common/enums',
				keyPrefix: 'RequirementStatus',
			}),
			filtRequirement[ii].systemLevels.map(level => level.name).join(';\n'),
			filtRequirement[ii].markets.map(market => market.name).join(';\n'),
			filtRequirement[ii].keywords.map(keyword => keyword.name).join(';\n'),
			filtRequirement[ii].tags.map(tags => tags.name).join(';\n'),
			filtRequirement[ii].version ?? '',
		]);

		intRowCount++;

		for (
			let jj = 0;
			jj < tempDivElement.getElementsByTagName('img').length;
			jj++
		) {
			arrImagesUri.push(
				tempDivElement
					.getElementsByTagName('img')[0]
					.getAttribute('src') as string,
			);
			arrImagesName.push(
				(filtRequirement[ii].requirementId as string) + '_' + jj + '.html',
			);
			row = sheet.getRow(intRowCount);
			row.outlineLevel = 1;
			row.getCell('B').fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: {argb: '8DB4E2'},
			};
			row.getCell('D').alignment = {
				indent: 1,
				wrapText: true,
				vertical: 'top',
			};
			row.getCell('D').style.font = {
				underline: true,
				bold: false,
				color: {argb: '00B0F0'},
			};
			table.addRow([
				'',
				'',
				{
					text: t('LinkImage', {ns: 'components/EntityList/ExcelExport'}),
					hyperlink:
						(('assets/' + filtRequirement[ii].requirementId) as string) +
						'_' +
						jj +
						'.html',
					tooltip: t('Link', {
						ns: 'components/EntityList/ExcelExport',
						keyPrefix: 'TooltipsText',
					}),
				},
				'',
				'',
				'',
				'',
				'',
				'',
				'',
			]);
			intRowCount++;
		}
	}

	table.commit();

	const strZipName = t('RequirementsHeader', {ns: 'features/requirements'});
	await createZIPXL(
		workbook,
		strZipName,
		t,
		arrTables,
		arrImagesUri,
		arrImagesName,
		[],
	);
};
