import {
	DefaultButton,
	Dialog,
	ITextFieldProps,
	PrimaryButton,
	Stack,
} from '@fluentui/react';
import {TextFieldWithTooltip} from 'features/localizedTooltips';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

export const CreateQuestionDialog: React.FC<any> = ({
	showDialog,
	setShowDialog,
}) => {
	const {t} = useTranslation('common/faq');
	const [value, setValue] = useState('');

	type OnChange = Exclude<ITextFieldProps['onChange'], undefined>;

	const handleChange: OnChange = (_event, newValue): void => {
		setValue(newValue ?? '');
	};
	const closeDialog = () => {
		setShowDialog(false);
		setValue('');
	};
	return (
		<Dialog
			hidden={!showDialog}
			modalProps={{
				isBlocking: true,
				isDarkOverlay: true,
				styles: {main: {maxWidth: 650}},
			}}
			dialogContentProps={{
				title: t('PostQuestion'),
			}}
			onDismiss={closeDialog}
		>
			<TextFieldWithTooltip
				multiline
				label={t('PostQuestion')}
				placeholder={t('PostQuestion')}
				onChange={handleChange}
				value={value}
				styles={{root: {margin: '1rem 0 0.5rem'}}}
				autoAdjustHeight
			/>
			<Stack
				horizontal
				horizontalAlign='end'
				tokens={{childrenGap: 5, padding: '20px 0 0 0'}}
			>
				<Stack.Item>
					<PrimaryButton
						onClick={closeDialog}
						ariaLabel={'ConfirmDialogButton'}
					>
						{t('Confirm')}
					</PrimaryButton>
				</Stack.Item>
				<Stack.Item>
					<DefaultButton onClick={closeDialog} ariaLabel={'CancelDialogButton'}>
						{t('Cancel')}
					</DefaultButton>
				</Stack.Item>
			</Stack>
		</Dialog>
	);
};
