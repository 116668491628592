import {useCommand} from 'hooks';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {CreateQuestionDialog} from './CreateQuestionDialog';
import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';

export const FaqCommand = () => {
	const {t} = useTranslation('common/faq');

	const [showQuestionDialog, setShowQuestionDialog] = React.useState(false);

	useCommand({
		key: 'PostQuestion',
		priority: 10,
		text: t('PostQuestion'),
		iconProps: {iconName: 'Chat'},
		onClick() {
			setShowQuestionDialog(true);
		},
		hidden: !(isDevEnvironment() || isTestEnvironment()),
	});

	return (
		<CreateQuestionDialog
			showDialog={showQuestionDialog}
			setShowDialog={setShowQuestionDialog}
		/>
	);
};
